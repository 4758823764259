import {API_CLIENT} from "@/services/backend-client";
import {ApiClient} from "./backend-client";
import routes from "../routes/routes";

const API_URL = '/admin/api/';

class AuthService {
    login(user) {
        return ApiClient
            .post(API_URL + 'auth/login', {
                email: user.email,
                password: user.password
            })
            .then(response => {
              localStorage.setItem('token', JSON.stringify(response.data.access_token))

              return response.data.access_token;
            });
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }

    register(user) {
        return ApiClient.post(API_URL + 'signup', {
            username: user.username,
            email: user.email,
            password: user.password
        });
    }

    user() {
      return ApiClient
        .get(API_URL + 'auth/user')
        .then(response => {
          localStorage.setItem('user', JSON.stringify(response.data.data))

          return response.data.data
        })
    }
}

export default new AuthService();

import axios from "axios";
import authHeader from "./auth-header";
import router from "../routes/router";
import store from "../store";

export let API_CLIENT = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

export class ApiClient {
  static get(url, params = {}) {
    let response = API_CLIENT.get(url, {
      headers: authHeader(),
      params: params,
    })


    response.catch(function (error) {
      if (error.response && error.response.status === 401) {
        store.dispatch("auth/logout");
        router.push({name: 'login'})
      } else if (error.response && error.response.status === 403) {
        router.back();
      } else if (error.response && error.response.status === 404) {
        router.back();
      }

    });

    return response
  }

  static post(url, data) {
    let response = API_CLIENT.post(url, data, {
      headers: authHeader()
    })

    response.catch(function (error) {
      if (error.response && error.response.status === 401) {
        store.dispatch("auth/logout");
        router.push({name: 'login'})
      } else if (error.response && error.response.status === 403) {
        router.back();
      } else if (error.response && error.response.status === 404) {
        router.back();
      }
    });

    return response
  }

  static put(url, data) {
    let response = API_CLIENT.put(url, data, {
      headers: authHeader()
    })

    response.catch(function (error) {
      if (error.response && error.response.status === 401) {
        store.dispatch("auth/logout");
        router.push({name: 'login'})
      } else if (error.response && error.response.status === 403) {
        router.back();
      } else if (error.response && error.response.status === 404) {
        router.back();
      }
    });

    return response
  }

  static delete(url) {
    let response = API_CLIENT.delete(url, {
      headers: authHeader(),
    })

    response.catch(function (error) {
      if (error.response && error.response.status === 401) {
        store.dispatch("auth/logout");
        router.push({name: 'login'})
      } else if (error.response && error.response.status === 403) {
        router.back();
      } else if (error.response && error.response.status === 404) {
        router.back();
      }
    });

    return response
  }
}

<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
    </base-header>
    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-card no-body>
            <b-card-header class="border-0">
              <el-row>
                <h3 class="mb-0">Клиники</h3>
              </el-row>
              <div class="mt-2">
                <el-row>
                  <el-col :span="3">
                    <label>ID</label>
                    <el-input v-model="filter.id" @change="sendFilter()">
                      <el-button slot="append" icon="el-icon-close" @click="clearColumnFilter('id')"></el-button>
                    </el-input>
                  </el-col>
                  <el-col :span="3" :offset="1">
                    <label>Название</label>
                    <el-input v-model="filter.title" @change="sendFilter()">
                      <el-button slot="append" icon="el-icon-close" @click="clearColumnFilter('title')"></el-button>
                    </el-input>
                  </el-col>
                  <el-col :span="3" :offset="1">
                    <label>Адрес</label>
                    <el-input v-model="filter.address" @change="sendFilter()">
                      <el-button slot="append" icon="el-icon-close" @click="clearColumnFilter('address')"></el-button>
                    </el-input>
                  </el-col>
                  <el-col :span="3" :offset="1">
                    <label>Город</label>
                    <el-row>
                      <el-select v-model="filter.city" placeholder="Select" @change="sendFilter()">
                        <el-option
                          :key=null
                          :label=null
                          :value=null
                        >
                        </el-option>
                        <el-option
                          v-for="item in cities"
                          :key="item.id"
                          :label="item.title"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-row>
                  </el-col>
                  <el-col :span="3" :offset="1">
                    <label>Район</label>
                    <el-select v-model="filter.district" placeholder="Select" @change="sendFilter()">
                      <el-option
                        :key=null
                        :label=null
                        :value=null
                      >
                      </el-option>
                      <el-option
                        v-for="item in districts"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <div style="float: right; right: 0; position: absolute; bottom: 0">
                    <el-col>
                      <el-button type="primary" @click="clearFilter()">Сбросить</el-button>
                    </el-col>
                  </div>
                </el-row>
              </div>
            </b-card-header>
            <el-table class="table-responsive table"
                      header-row-class-name="thead-light" :data="rows">
              <el-table-column label="#ID"
                               prop="id"
                               min-width="90px">
              </el-table-column>
              <el-table-column label="Лого"
                               width="140px">
                <template v-slot="{row}">
                  <div v-if="row.logo !== null">
                    <a class="avatar rounded-circle mr-3">
                      <img alt="Логотип" :src="row.logo.link">
                    </a>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Название"
                               prop="title"
                               min-width="100px">
              </el-table-column>
              <el-table-column label="Адрес"
                               prop="address"
                               min-width="100px">
              </el-table-column>
              <el-table-column label="Город"
                               prop="city.title"
                               min-width="100px">
              </el-table-column>
              <el-table-column label="Район"
                               prop="district.title"
                               min-width="100px">
              </el-table-column>
              <el-table-column label="Заявки"
                               width="200px">
                <template v-slot="{row}">
                  <div v-if="row.countAppointments === 0">
                    <el-tag type="success">
                      {{ row.countAppointments }}
                    </el-tag>
                  </div>
                  <div v-else>
                    <el-tag type="danger">
                      {{ row.countAppointments }}
                    </el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Опубликована"
                               width="200px">
                <template v-slot="{row}">
                  <div v-if="row.published !== null">
                    <el-tag type="success">
                      <i class="el-icon-circle-check"></i>
                    </el-tag>
                  </div>
                  <div v-else>
                    <el-tag type="danger">
                      <i class="el-icon-circle-close"></i>
                    </el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" align="right" min-width="300px">
                <template slot="header">
                  <el-button type="primary" @click="modalCreate = true">Создать</el-button>
                </template>
                <template v-slot="{row}">
                  <el-button type="primary" @click="clinic(row.id)">Редактировать</el-button>
                    <el-button v-if='admin && row.published == null' type="primary" @click="clinicPublished(row.id)">Опубликовать</el-button>
                    <el-button v-if="admin && row.published" type="primary" @click="clinicPublished(row.id)">Снять с публикации</el-button>

                </template>
              </el-table-column>
            </el-table>

            <b-card-footer class="py-4 d-flex justify-content-end">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" tabindex="-1" @click="paginate(1)">
                    <i class="fa fa-angle-double-left"></i>
                    <span class="sr-only">First Page</span>
                  </a>
                </li>
                <div v-for="page in pages">
                  <div v-if="currentPage !== page">
                    <li class="page-item" @click="paginate(page)">
                      <a class="page-link">{{ page }}</a>
                    </li>
                  </div>
                  <div v-else>

                    <li class="page-item active" disabled>
                      <a class="page-link">{{ page }}</a>
                    </li>
                  </div>
                </div>
                <li class="page-item">
                  <a class="page-link" tabindex="-1" @click="paginate(lastPage)">
                    <i class="fa fa-angle-double-right"></i>
                    <span class="sr-only">Last Page</span>
                  </a>
                </li>
              </ul>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-sm" size="xl"
             title="Создать клинику"
             v-model="modalCreate">
      <create-clinic ref="createClinic"></create-clinic>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-left"
            @click="createClinic"
          >
            Создать
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="modal-sm" size="sm"
             v-model="modalOk"
    >Успешно
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            @click="modalOk=false"
          >
            Окей
          </b-button>
        </div>
      </template>
    </b-modal>

  </div>

</template>

<script>
import SampleNavbar from "../../Starter/SampleNavbar";
import ContentFooter from "../../Layout/ContentFooter";
import DashboardContent from "../../Layout/Content";
import {FadeTransition} from 'vue2-transitions';
import {published} from "../../../services/clinic.service";
import {ApiClient} from '../../../services/backend-client'
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Input,
  InputNumber,
  Option,
  Row,
  Select,
  Table,
  TableColumn,
  Tag
} from 'element-ui'
import CreateClinic from "./components/CreateClinic";

const API_URL = '/admin/api/';

export default {
  components: {
    CreateClinic,
    SampleNavbar,
    ContentFooter,
    DashboardContent,
    FadeTransition,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [Button.name]: Button,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Row.name]: Row,
    [Col.name]: Col,
    [InputNumber.name]: InputNumber,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tag.name]: Tag,
  },
  data() {
    return {
      lastPage: 1,
      currentPage: 1,
      total: 0,
      rows: [],
      pages: [],
      cities: [],
      districts: [],
      modalCreate: false,
      modalOk: false,
      filter: {
        id: null,
        title: null,
        address: null,
        city: null,
        district: null,
      },
      stringFilter: '',
      page: 1,
    };
  },
  methods: {
    paginate(page) {
      this.page = page;
      this.getClinics();
    },
    getClinics() {
      ApiClient.get(API_URL + 'clinics' + '?page=' + this.page + this.stringFilter)
        .then(response => {
          this.rows = response.data.data;
          this.lastPage = response.data.meta.last_page;
          this.currentPage = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.pages = [];

          if (this.currentPage < 5) {
            for (let i = 1; i <= 10; i++) {
              this.pages.push(i);
              if (i === this.lastPage) {
                break;
              }
            }
          } else {
            for (let i = this.currentPage - 3; i <= 10 + this.currentPage - 3; i++) {
              this.pages.push(i);
              if (i === this.lastPage) {
                break;
              }
            }
          }
        })
    },
    getCities() {
      ApiClient.get(API_URL + 'cities')
        .then(response => {
          this.cities = response.data.data
        })
    },
    getDistricts() {
      ApiClient.get(API_URL + 'districts')
        .then(response => {
          this.districts = response.data.data
        })
    },
    sendFilter() {
      let stringFilter = ''

      for (const [key, value] of Object.entries(this.filter)) {
        if (value !== null) {
          stringFilter += '&' + key + '=' + value
        }
      }
      this.stringFilter = stringFilter;

      this.getClinics();
    },
    clearFilter() {
      this.filter = {
        id: null,
        title: null,
        address: null,
        city: null,
        district: null,
      };

      this.sendFilter();
    },
    clearColumnFilter(column) {
      this.filter[column] = null;
      this.sendFilter();
    },
    clinic(id) {
      this.$router.push('/clinics/' + id)
    },
    createClinic() {
      this.$refs.createClinic.createClinic();
      this.getClinics();
      this.modalCreate = false;
      this.modalOk = true;
    },

    clinicPublished(id) {
      published(id);
      this.getClinics();
      this.modalOk = true;
    },
  },
  mounted() {
    this.getClinics();
    this.getCities();
    this.getDistricts();
  },

  computed: {
    admin() {
      if (this.$store.getters["auth/user"]) {
        return this.$store.getters["auth/user"].role === 'super-admin';
      }
    },
  },
};
</script>
<style>
.page-item.active .page-link {
  background-color: #0c84ff;
  border-color: #0c84ff;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[(_vm.admin)?_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Статистика',
          path: '/dashboard',
          icon: 'ni ni-tv-2 text-primary',
        }}})],1):_vm._e(),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Клиники',
          path: '/clinics',
          icon: 'ni ni-building text-primary'
        }}})],1),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Врачи',
          path: '/doctors',
          icon: 'ni ni-fat-add text-primary'
        }}})],1),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Пациенты',
          path: '/patients',
          icon: 'ni ni-single-02 text-primary'
        }}})],1),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Заявки на скорую помощь',
          path: '/emergency',
          icon: 'ni ni-bus-front-12 text-primary'
        }}})],1),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Отзывы',
          path: '/feedbacks',
          icon: 'ni ni-chat-round text-primary'
        }}})],1),_c('template',{slot:"admin"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Симптомы',
          path: '/symptoms',
          icon: 'ni ni-spaceship text-primary'
        }}})],1),_c('template',{slot:"admin"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Специализации',
          path: '/specialties',
          icon: 'ni ni-spaceship text-primary'
        }}})],1),_c('template',{slot:"admin"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Категории',
          path: '/categories',
          icon: 'ni ni-spaceship text-primary'
        }}})],1),_c('template',{slot:"admin"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Анализы',
          path: '/analyses',
          icon: 'ni ni-spaceship text-primary'
        }}})],1),_c('template',{slot:"admin"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Реф. Группы',
          path: '/references/groups',
          icon: 'ni ni-spaceship text-primary'
        }}})],1),_c('template',{slot:"admin"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Специализации клиник',
          path: '/clinic/specialties',
          icon: 'ni ni-spaceship text-primary'
        }}})],1),_c('template',{slot:"admin"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Состояние пациента',
          path: '/states',
          icon: 'ni ni-spaceship text-primary'
        }}})],1),_c('template',{slot:"admin"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Вакцины',
          path: '/vaccines',
          icon: 'ni ni-spaceship text-primary'
        }}})],1),_c('template',{slot:"admin"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Города',
          path: '/cities',
          icon: 'ni ni-spaceship text-primary'
        }}})],1),_c('template',{slot:"admin"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Районы',
          path: '/districts',
          icon: 'ni ni-spaceship text-primary'
        }}})],1),_c('template',{slot:"admin"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Аптеки',
          path: '/pharmacies',
          icon: 'ni ni-spaceship text-primary'
        }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
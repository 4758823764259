import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from "../store";

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.state.auth.status.loggedIn) next({ name: 'login' })
  else next()
});

export default router;

import AuthService from '../services/auth.service';
import {dispatch} from "d3";

const token = JSON.parse(localStorage.getItem('token'));

const initialState = token
  ? {status: {loggedIn: true}, token, user: null}
  : {status: {loggedIn: false}, token: null};

export const auth = {
  namespaced: true,
  state: initialState,
  getters: {
    user(state) {
      return state.user
    }
  },
  actions: {
    getUser({commit}) {
      AuthService.user().then(user => {
        console.log(user)
        commit('setUser', user)
      })
    },
    login({commit}, user) {
      return AuthService.login(user).then(
        function (token) {
          commit('loginSuccess', token);
          AuthService.user().then(user => {
            commit('setUser', user)
          })
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({commit}) {
      AuthService.logout();
      commit('logout');
    },
    register({commit}, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    loginSuccess(state, token) {
      state.status.loggedIn = true;
      state.token = token;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.token = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.token = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};

<template>
  <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
    <div class="container-fluid">

      <!--Toggler-->
      <navbar-toggle-button @click.native="showSidebar">

      </navbar-toggle-button>
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/logo.png" class="navbar-brand-img" alt="...">
      </router-link>

      <slot></slot>
      <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">

        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img>
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav">
          <slot name="links">
          </slot>
        </ul>
        <!--Divider-->
        <hr class="my-3" v-if="admin">
        <!--Heading-->
        <h6 class="navbar-heading text-muted" v-if="admin">Администрирование</h6>
        <!--Navigation-->

        <ul class="navbar-nav" v-if="admin">
          <slot name="admin">
          </slot>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from '@/components/NavbarToggleButton'
import store from "../../store";
export default {
  name: 'sidebar',
  components: {
    NavbarToggleButton
  },
  props: {
    autoClose: {
      type: Boolean,
      default: true,
      description: 'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },

  data () {
    return {
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  computed: {
    admin() {
      if (this.$store.getters["auth/user"]) {
        return this.$store.getters["auth/user"].role === 'super-admin';
      }
    },
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true)
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  created() {
  },
};
</script>

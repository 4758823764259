import {ApiClient} from "./backend-client";

const url = 'admin/api/clinic/specialties/'

export function list(title, page) {
  return ApiClient.get(url, {title, page: page})
}

export function get(id) {
  return ApiClient.get(url + id)
}

export function create(data) {
  return ApiClient.post(url + 'create', data)
}

export function update(id, data) {
  return ApiClient.put(url + id + '/update', data)
}

export function deleteSpecialty(id) {
  return ApiClient.delete(url + id + '/delete')
}

import {ApiClient} from "./backend-client";

const uriValues = 'admin/api/cities/';

export function list(title, page) {
  return ApiClient.get(uriValues, {search: title, page: page,})
}

export function create(data) {
  return ApiClient.post(uriValues + 'create', data);
}

export function update(id, data) {
  return ApiClient.put(uriValues + id + '/update', data);
}

import {ApiClient} from "./backend-client";

export function list(search = "", page = 1, perPage = 10, not_clinic_id) {
  return ApiClient.get("admin/api/clinics",
    {
      search: search,
      page: page,
      perPage: perPage,
      not_clinic_id: not_clinic_id,
    }
  )
}

export function get(id) {
  return ApiClient.get('admin/api/clinics/' + id);
}

export function published(id) {
  return ApiClient.post('admin/api/clinics/' + id + '/published');
}
